const nailTreatmentData = [
  {
    id: 1,
    treatment: 'Маникюр',
    price: '700',
  },
  {
    id: 2,
    treatment: 'Маникюр с покрытием однотонный',
    price: '1200',
  },
  {
    id: 3,
    treatment: 'Маникюр с укреплением',
    price: '1500',
  },
  {
    id: 4,
    treatment: 'Педикюр без покрытия',
    price: '1300',
  },
  {
    id: 5,
    treatment: 'Педикюр с покрытием',
    price: '1600',
  },
  {
    id: 6,
    treatment: 'Педикюр с покрытием + полировка стопы',
    price: '2200',
  },
  {
    id: 7,
    treatment: 'Педикюр без покрытия и  полировка стопы',
    price: '1800',
  },
  {
    id: 8,
    treatment: 'Снять другие',
    price: '100',
  },
  {
    id: 9,
    treatment: 'Ремонт',
    price: '100',
  },
  {
    id: 10,
    treatment: 'Дизайн',
    price: '50',
  },
  {
    id: 11,
    treatment: 'Дизайн сложный',
    price: '100',
  },
  {
    id: 12,
    treatment: 'Френч',
    price: '300',
  },
  {
    id: 13,
    treatment: 'Втирка',
    price: '200',
  },
  {
    id: 14,
    treatment: 'Наращивание',
    price: '2200',
  },
  {
    id: 15,
    treatment: 'Омбре градиент',
    price: '300',
  },
  
]

export default nailTreatmentData;