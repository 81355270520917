import EarImage1 from '../images/ear/ear_1.jpg';
import EarImage2 from '../images/ear/ear_2.jpg';
import EarImage3 from '../images/ear/ear_3.jpg';

const earCardsData = [
  {
    id: 1,
    image: `${EarImage1}`,
  },
  {
    id: 2,
    image: `${EarImage2}`,
  },
  {
    id: 3,
    image: `${EarImage3}`,
  },
 
]

export default earCardsData;