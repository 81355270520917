const earLinks = [
  {
    id: 1,
    linkTitle: 'Портфолио',
    linkUrl: 'portfolio',
  },
  {
    id: 2,
    linkTitle: 'Процедуры и цены',
    linkUrl: 'treatments',
  },
  {
    id: 3,
    linkTitle: 'Видео',
    linkUrl: 'videos',
  },
]

export default earLinks;