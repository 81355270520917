import React from 'react';
import SectionTitle from './SectionTitle';

const Portfolio = ({ portfolio }) => {
  
  return (
    <section className='portfolio' id='portfolio'>
      <SectionTitle>портфолио</SectionTitle>
      <div className="portfolio__content">
        {portfolio}
      </div>
    </section>
  );
}

export default Portfolio;