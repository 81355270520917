import React from 'react';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import Portfolio from '../components/Portfolio';
import BeforeAfter from '../components/BeforeAfter';
import Videos from '../components/Videos';
import Treatments from '../components/Treatments';
import Hero from '../components/Hero';
import ScrollToTop from '../components/ScrollToTop';
import manicureLinks from '../data/manicureLinks';
import nailTreatmentData from '../data/nailTreatmentData';
import Cards from '../components/Cards';
import nailVideoData from '../data/nailVideoData';

const NailPage = () => {
  return (
    <>
      <Nav /> 
      <Hero 
        background='hero'
        title='Маникюр'
        data={manicureLinks}
      />
      <Portfolio 
        portfolio={<Cards />}
      />
      <Treatments 
        data={nailTreatmentData}
      />
      <Videos 
        data={nailVideoData}
      /> 
      <BeforeAfter />
      <ScrollToTop />
      <Footer />
    </>
  );
}

export default NailPage;