import video1 from '../images/videos/ear/ear_video_1.mp4';
import video1poster from '../images/ear/video1poster.jpg';

const nailVideoData = [
  { 
    id: 1,
    video: `${video1}`,
    poster: `${video1poster}`
  },
  
]

export default nailVideoData;