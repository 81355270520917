import React from 'react';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import Portfolio from '../components/Portfolio';
import ScrollToTop from '../components/ScrollToTop';
import Hero from '../components/Hero';
import earLinks from '../data/earLinks';
import CardsEar from '../components/CardsEar';
import Treatments from '../components/Treatments';
import earTreatmentData from '../data/earTreatmentData';
import earVideoData from '../data/earVideoData';
import Videos from '../components/Videos';

const EarPage = () => {
  return (
    <>
     <Nav /> 
      <Hero 
        background='hero hero_ear'
        title='Прокол мочки уха'
        data={earLinks}
      />
      <Portfolio 
        portfolio={<CardsEar />}
      />
      <Treatments 
        data={earTreatmentData}
      />
      <Videos 
        data={earVideoData}
      /> 
      <ScrollToTop />
      <Footer />
    </>
  );
}

export default EarPage;