import React from 'react';
import { Link as LinkScroll } from 'react-scroll';
import { FaChevronDown } from "react-icons/fa";

const Hero = ({ title, data, background }) => {
  return (
    <header className={background}>
      <div className="hero__content">
        <h1 className="hero__title">
          {title}
        </h1>
        <ul className="hero__list">
          {data.map((link) => (
            <li key={link.id} className='hero__item'>
              <LinkScroll 
                to={link.linkUrl}
                className="hero__link"
                spy={true} 
                smooth={true}
                offset={-20} 
                duration={1000}
              >
                {link.linkTitle}
                <FaChevronDown className='icon' />
              </LinkScroll>
            </li>
          ))}
        </ul>
      </div>
    </header>
  );
}

export default Hero;