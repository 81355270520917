import { Route, Switch } from 'react-router-dom';
import Homepage from '../pages/Homepage';
import NailPage from '../pages/NailPage';
import PageNotFound from './PageNotFound';
import EarPage from '../pages/EarPage';
import About from './About';

function App() {
  return (
    <div className="app">
      <Switch>
        <Route exact path='/'>
          <Homepage />
        </Route>
        <Route path='/manicure'>
          <NailPage />
        </Route>
        <Route path='/ear-piercing'>
          <EarPage />
        </Route>
        <Route path='/about'>
          <About />
        </Route>
        <Route path='*'>
          <PageNotFound />
        </Route> 
      </Switch> 
    </div>
  );
}

export default App;
