import React from 'react';
import Nav from '../components/Nav';
import Header from '../components/Header';
import About from '../components/About';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import ScrollToTop from '../components/ScrollToTop';
import Testimonials from '../components/Testimonials';
import Features from '../components/Features';
import Certificates from '../components/Certificates';

const Homepage = () => {
  return (
    <>
      <Nav /> 
      <Header />
      <Features />
      <About />
      <Certificates />
      <Testimonials /> 
      <Contact />
      <Footer />  
      <ScrollToTop />
    </>
  );
}

export default Homepage;