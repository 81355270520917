import React, { useState, useEffect } from 'react';
import Popup from './Popup';
import { MdOutlineZoomOutMap } from "react-icons/md";

const Card = ( {item} ) => {
  const [popupActive, setPopupActive] = useState(false);
 
  // Popup esc close
  useEffect(() => {
    document.addEventListener("keydown", handleEscapeKey);
      return () => {
        document.removeEventListener("keydown", handleEscapeKey);
      };
  }, [popupActive]);

  function handleEscapeKey(e) {
    if(e.key === 'Escape') {
      setPopupActive(false)
    }   
  }

  return (
    <>
      <li className="card" onClick={() => setPopupActive(true)}>
        <img 
          className='card__image' 
          src={process.env.REACT_APP_UPLOAD_URL+item.attributes?.image?.data?.attributes?.url} 
          alt="изображения ногтей" 
        />
        <div className="card__overlay" onClick={() => setPopupActive(true)}>
          <p className='card__icon'><MdOutlineZoomOutMap /></p>
        </div> 
      </li>

      <Popup 
        active={popupActive} 
        setActive={setPopupActive} 
      >
        <img 
          className='popup__image' 
          src={process.env.REACT_APP_UPLOAD_URL+item.attributes?.image?.data?.attributes?.url} 
          alt="изображения ногтей" 
        />
      </Popup>
    </>
  );
}

export default Card;