import React, { useState } from 'react';
import CardEar from './CardEar';
import earCardsData from '../data/earCardsData';

const CardsEar = () => {

  // MORE CARDS
  const [moreCards, setMoreCards] = useState(9);
  const moreCardsAmount = 6;

  const handleMoreCards = () => {
    setMoreCards((prevValue) => prevValue + moreCardsAmount)
  }

  return (
    <>
     
      <ul className="cards">
        {earCardsData.slice(0, moreCards).map((item) => (
          <CardEar
            key={item.id}
            item={item}
          />
        ))}
      </ul> 
    
      {moreCards < earCardsData.length && (
        <button 
          className='cards__more' 
          type='button' 
          onClick={handleMoreCards}
        > 
          Ещё
        </button>
      )}
    </>
  );
}

export default CardsEar;