import { GoPerson } from "react-icons/go";
import { MdMailOutline, MdOutlineReviews } from "react-icons/md";
import { FaServicestack, FaThLarge, FaHome, FaVideo  } from "react-icons/fa";
import { CgArrowsBreakeH } from "react-icons/cg";
import { GiDiploma } from "react-icons/gi";
import { FaEarListen } from "react-icons/fa6";
import { GiFingernail } from "react-icons/gi";

const navLinks = [
  {
    id: 1,
    section: '/',
    text: 'Главная',
    icon: <FaHome  />,
  },
  {
    id: 2,
    section: '/manicure',
    text: "Маникюр",
    icon: <GiFingernail />,
  },
  {
    id: 3,
    section: '/ear-piercing',
    text: "Прокол мочки уха",
    icon: <FaEarListen />,
  },
  {
    id: 4,
    section: '/#about',
    text: "Обо мне",
    icon: <GoPerson />,
  },
  {
    id: 5,
    section: '/#contact',
    text: "Контакты",
    icon: <MdMailOutline />,
  },
]

export default navLinks;