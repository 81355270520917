import React, { useState } from 'react';
import SectionTitle from './SectionTitle';

const Videos = ({ data }) => {

  // MORE CARDS
  const [moreCards, setMoreCards] = useState(6);
  const moreCardsAmount = 3;

  const handleMoreCards = () => {
    setMoreCards((prevValue) => prevValue + moreCardsAmount)
  }

  return (
    <section className='videos'>
      <SectionTitle>видео</SectionTitle>
      
      <div className="videos__content">

          <ul className="videos__list">
            {data.slice(0, moreCards).map((item) => (
              <li 
                key={item.id} 
                className="videos__item"
              >
                <video 
                  className='videos__video'
                  src={item.video}
                  poster={item.poster}
                  controls
                  muted 
                >
                  Your browser does not support HTML5 video.
                </video>
              </li>
            ))}
        </ul>

        {moreCards < data.length && (
        <button 
          className='cards__more' 
          type='button' 
          onClick={handleMoreCards}
        > 
          Ещё
        </button>
      )}

      </div>
    </section>
  );
}

export default Videos;