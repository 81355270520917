import React from 'react';
import { Link } from 'react-router-dom';
import { GrLinkNext } from "react-icons/gr";

const Features = () => {

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <section className='features' id='features'>
      <ul className="features__list">

        <li className="features__item">
          <Link to="/manicure" onClick={scrollToTop}>
            <div className="features__background"></div>
              <h2 className='features__title'>Маникюр</h2>
              <p className='features__text'>
                Мир красоты на кончиках пальцев!
              </p>
              <div className='features__icon'>
                <GrLinkNext className='icon' />
              </div>
          </Link>
        </li> 

        <li className="features__item">
          <Link to="/ear-piercing" onClick={scrollToTop}>
            <div className="features__background features__background_ear"></div>
              <h2 className='features__title'>Прокол мочки уха</h2>
              <p className='features__text'>
                 Идеальный прокол — идеальный стиль!
              </p>
              <div className='features__icon'>
                <GrLinkNext className='icon' />
              </div>
          </Link>
        </li> 

      </ul>
    </section>
  );
}

export default Features;