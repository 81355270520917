import React from 'react';
import contactData from '../data/contactData';
import { FaHeart } from "react-icons/fa6";
import Social from './Social';
import SectionTitle from './SectionTitle';
import LinkButton from './LinkButton';

const Contact = () => {
  return (
    <section className='contact' id='contact'>
      <SectionTitle>контакты</SectionTitle> 
      <div className="contact__content">

        <div className="contact__list-container">
          <ul className="contact__list">
            {contactData.map((contact) => (
              <li key={contact.id} className="contact__item">
              <p className="contact__info">
                <span className="icon">{contact.icon}</span>
                {contact.text}
              </p>
            </li>
            ))}
          </ul>
          <div className="contact__social-info">
            <Social />
            <p className='contact__social-text'>
              Подписывайтесь!
              <FaHeart className='icon' />
            </p>
          </div>
        </div>

        <div className="contact__text-container">
          <p className="contact__text">
            Я всегда рада новым клиентам и готова помочь вам найти идеальный
            стиль для ваших ногтей, который подчеркнет вашу индивидуальность
            и подойдет вашему образу жизни. 
          </p>
          <p className="contact__text">
            Запишитесь на прием сегодня, и
            давайте начнем ваше путешествие к красивым ногтям вместе!
            Не стесняйтесь писать мне в социальных сетях, 
            чтобы увидеть мои последние работы и вдохновиться для следующего
            визита.
          </p>
          <LinkButton link='features'>
            Услуги
          </LinkButton>
        </div>

      </div>
    </section>
  );
}

export default Contact;