import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { LuAmpersand } from "react-icons/lu";
import navLinks from '../data/navLinks';
import contactData from '../data/contactData';
import Logo from './Logo';
import Social from './Social';

const Footer = () => {

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <section className='footer'>
      <div className="footer__content">

        <div className="footer__info">
          <div className='footer__logo'>
            <Logo />
          </div>
          <nav className='footer__nav'>
            <ul className="footer__list">
              <h3 className='footer__title'>меню</h3>

              {navLinks.map((link) => {
                return (
                  <li 
                    className="footer__item"
                    key={link.id}
                  >
                    <HashLink
                      to={link.section}
                      className="footer__link" 
                      onClick={scrollToTop}
                    >
                      <span className="icon">{link.icon}</span>
                      {link.text}
                    </HashLink>
                  </li>
                )
              })}
            </ul>

            <ul className="footer__list">
              <h3 className='footer__title'>контакты</h3>

              {contactData.map((contact) => (
                <li key={contact.id} className="footer__item">
                  <p className='footer__contact'>
                    <span className="icon">{contact.icon}</span>
                    {contact.text}
                  </p>
                </li>
              ))}
            </ul>

            <Social />
          </nav>
        </div>

        <div className="footer__copy-container">
          <p className='footer__copy'>
            &copy; <span>{new Date().getFullYear()}</span>, Diana Beauty Care
          </p>
          <p className="footer__copy">
            Designed <LuAmpersand /> Developed by T.O.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Footer;