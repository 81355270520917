import React from 'react';
import { Link } from 'react-router-dom';
import LogoImage from '../images/logo/diana_logo_thick.png';

const Logo = () => {

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <div className='logo'>
      <Link to="/" onClick={scrollToTop} className='logo__link'>
        <img 
          className='logo__image' 
          src={LogoImage} 
          alt="logo" 
        /> 
      </Link>
    </div>
  );
}

export default Logo;