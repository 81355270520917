import React from 'react';
import SectionTitle from './SectionTitle';
import CertificatOne from '../images/certificates/cert_1.jpg'
import CertificatTwo from '../images/certificates/cert_2.jpg'

const Certificates = () => {
  return (
    <section className='certificates'>
      <SectionTitle>Сертификаты</SectionTitle>
      <div className="certificates__content">
        <ul className="certificates__list">
          <li className="certificates__item">
            <img 
              className='certificates__image'
              src={CertificatOne}
              alt="Сертификаты" 
            />
          </li>
          <li className="certificates__item">
            <img 
              className='certificates__image'
              src={CertificatTwo}
              alt="Сертификаты" 
            />
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Certificates;