import React from 'react';

const Hamburger = ({ onClick, sidebarActive }) => {
  return (
    <div className={sidebarActive ? "hamburger active" : "hamburger"} onClick={onClick}>
      <div className="hamburger__content">
        <div className="bar bar_top"></div>
        <div className="bar bar_middle"></div>
        <div className="bar bar_bottom"></div>
      </div>
    </div>
  );
}

export default Hamburger;