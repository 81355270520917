import React from 'react';
import { FaRubleSign } from "react-icons/fa6";
import SectionTitle from './SectionTitle';

const Treatments = ({ data }) => {
  return (
    <section className='treatments' id='treatments'>
      <SectionTitle>
        процедуры и цены
      </SectionTitle> 
      <div className='treatments__content'>

        <ul className="treatments__list">
          {data.map((treatment) => (
            <li 
              key={treatment.id} 
              className="treatments__item"
            >
              <p className="treatments__treatment">
               {treatment.treatment}
              </p>
              <p className="treatments__price">
                {treatment.price} <FaRubleSign />
              </p>
            </li>
          ))}
        </ul>
        
      </div>
    </section>
  );
}

export default Treatments;